
import AvatarImage from "@/components/AvatarImage.vue";
import CustomButton from "@/components/CustomButton.vue";

import { ServersModule } from "@/store/modules/servers";
import { PopoutsModule } from "@/store/modules/popouts";
import { joinServerByCode } from "@/services/serverService";
import { PropType } from "vue";

interface Server {
  avatar: string;
  server_id: string;
  banner: string;
  name: string;
  default_channel_id: string;
}

import { defineComponent } from "vue";
export default defineComponent({
  name: "PreviewServer",
  components: { AvatarImage, CustomButton },
  props: {
    server: {
      type: Object as PropType<{ json: Server; code: string }>,
      required: true,
    },
  },
  data() {
    return {
      requestSent: false,
      error: null as string | null,
    };
  },
  computed: {
    bannerURL(): any {
      if (!this.server.json.banner) return undefined;
      return process.env.VUE_APP_NERTIVIA_CDN + this.server.json.banner;
    },
    isJoined(): any {
      return ServersModule.servers[this.server.json.server_id];
    },
  },
  methods: {
    visitServer() {
      PopoutsModule.ClosePopout("add-server");
      this.$router.push(
        `/app/servers/${this.server.json.server_id}/${this.server.json.default_channel_id}`
      );
    },

    joinServer() {
      PopoutsModule.ShowPopout({
        id: "captcha-popout",
        component: "CaptchaPopout",
        data: {
          callback: this.captchaVerified,
        },
      });
    },
    captchaVerified(token: string) {
      if (this.requestSent) return;
      this.requestSent = true;
      this.error = null;
      joinServerByCode(this.server.code, { socketID: this.$socket.id, token })
        .then(() => {
          PopoutsModule.ClosePopout("add-server");
          this.requestSent = false;
        })
        .catch(async (err) => {
          this.requestSent = false;
          if (!err.response) return (this.error = "Cannot connect to server.");
          const result = await err.response.json();
          this.error = result.message;
        });
    },
  },
});
