
import { PropType, h } from "vue";
interface Tab {
  name: string;
  id: string;
  component: any;
  props?: any;
  events?: string[];
}

import { defineComponent } from "vue";
export default defineComponent({
  data() {
    return {
      currentTabIndex: 0,
    };
  },
  props: {
    tabs: {
      type: Array as PropType<Tab[]>,
      required: true,
    },
  },
  methods: {
    onEvent(data: any, name: string) {
      this.$emit("event", {
        eventName: name,
        id: this.currentTab.id,
        data,
      });
    },
  },
  computed: {
    currentTab(): Tab {
      return this.tabs[this.currentTabIndex];
    },

    events(): any {
      const events: any = {};
      this.currentTab.events?.forEach((v) => {
        events[v] = (data: any) => this.onEvent(data, v);
      });
      return events;
    },
  },
});
