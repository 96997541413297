
import { PopoutsModule } from "@/store/modules/popouts";
import CreateServer from "./CreateServer.vue";
import JoinServer from "./JoinServer.vue";
import TabLayout from "@/components/TabLayout.vue";

import { defineComponent } from "vue";
export default defineComponent({
  name: "ProfilePopout",
  components: { TabLayout },
  computed: {
    tabs(): any {
      return [
        { name: this.$t("add-server-popout.tabs.join"), component: JoinServer },
        {
          name: this.$t("add-server-popout.tabs.create"),
          component: CreateServer,
        },
      ];
    },
  },
  methods: {
    close() {
      PopoutsModule.ClosePopout("add-server");
    },
    backgroundClick(event: any) {
      if (event.target.classList.contains("popout-background")) {
        this.close();
      }
    },
  },
});
