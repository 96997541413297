
import EnterServerCode from "./EnterServerCode.vue";
import ServerPreview from "./ServerPreview.vue";

import { defineComponent } from "vue";
export default defineComponent({
  name: "JoinServer",
  components: { EnterServerCode, ServerPreview },
  data() {
    return {
      server: null as any,
    };
  },
});
