
import CustomInput from "@/components/CustomInput.vue";
import CustomButton from "@/components/CustomButton.vue";
import { getServerInfoByCode } from "@/services/serverService";

import { defineComponent } from "vue";
export default defineComponent({
  name: "EnterServerCode",
  components: { CustomInput, CustomButton },
  data() {
    return {
      code: "",
      requestSent: false,
      error: null as string | null,
    };
  },
  methods: {
    joinServer() {
      if (this.requestSent) return;
      this.requestSent = true;
      this.error = null;
      let trimmedCode = this.code.trim();
      // check if invite code is a link
      trimmedCode = trimmedCode.split("/")[trimmedCode.split("/").length - 1];
      if (!trimmedCode.length) {
        this.requestSent = false;
        return (this.error = "Cannot join emptiness.");
      }

      getServerInfoByCode(trimmedCode)
        .then((json) => {
          this.$emit("success", { json, code: trimmedCode });
        })
        .catch(async (err) => {
          if (!err.response) {
            this.error = "Cannot connect to server.";
          } else {
            this.error = (await err.response.json()).message;
          }
          this.requestSent = false;
        });
    },
  },
});
